import React from "react"
import {
  Section,
  Container,
  Column,
  Columns,
  Button,
  Content,
} from "bloomer"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import data from "../../data/oraculo/baralho-cigano/baralho-cigano.json"

export default class TarotDoAmor extends React.Component {
  state = {
    selected: [],
    random:[],
  }

  
  handleClick = event => {
    let random = data;
    for (let i = random.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [random[i], random[j]] = [random[j], random[i]];
    }
    //let random = data.sort(() => .5 - Math.random())
    this.setState({random:random})
    this.setState({selected:[]})
  }

  render() {
    var parent = this
    const {selected, random} = this.state;
    let items =""
    if (selected.length === 3){
         items = selected.map(function(item, i){
            if(item['id']){
                const image = require("../../data/oraculo/baralho-cigano/images/"+item["id"]+".png")
                return (
                <Content key={i} className="media">
                    <div className="media-left">
                        <img src={image} alt={item['title']} />
                    </div>
                    <div className="media-content">
                        <h3>Nome: {item["title"]}</h3>
                        <p>Descrição: {item["description"]}</p>
                        <p>Interpretação: {item["interpretation"]}</p>
                    </div>
                </Content>
                )
            }else{
                return ""
            }
            
        });
        items.push(<Button className="is-primary" onClick={() => { parent.handleClick()}}>Jogar</Button>)

    }
    if(random.length && selected.length<3){
         items = random.map(function(item, i){
            if(item['id']){
                const active = selected.includes(item)
                const image = require("../../data/oraculo/baralho-cigano/images/"+item["id"]+".png")
                return (
                       <Button 
                            key={i}
                            onClick={() => {
                                if(!active){
                                  const selectedtmp = selected;   
                                  selectedtmp.push(item);   
                                  parent.setState({selected:selectedtmp})
                                }                            
                            }}
                            className={active?'is-active column ':'column'}
                        >
                            <img src={image} alt={item['name']}/>
                        </Button>
                
                )
            }else{
                return ""
            }
            
        });
        items = <Columns className="deck" isMobile isMultiline>{items}</Columns>
    }
    if(selected.length<3 && !random.length){
      items = <Button className="is-primary" onClick={() => { parent.handleClick()}}>Jogar</Button>
    }
    
 
    return (
      <Layout>
        <SEO title="Tarot do Amor Gratis - Estrela Guia" />
          <Container>
            <Columns>
              <Column>
                <Content>
                  <h1>Tarot do Amor</h1>
                                
                </Content>
                <Content>
                  {items}
                </Content>
              </Column>
            </Columns>
          </Container>
      </Layout>
    )
  }
}


